import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="immunstaerkung" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Immunstärkung`}</h1>
      <h3 {...{
        "id": "immunstarkung"
      }}>{`Immunstärkung`}</h3>
      <p>{`Wenn man sich schlapp fühlt, immer wieder Infekte auftreten, die körperliche und geistige Belastbarkeit nachlässt, dann können dies deutliche Zeichen einer Schwächung des Immunsystems sein.`}</p>
      <p>{`Ursachen gibt es viele: Stress, Fehlernährung, Schadstoffe der Umwelt, Genussgifte, Medikamente, Bewegungsmangel…`}</p>
      <p>{`Die Naturheilkunde bietet vielfältige Therapien zur Immunstimulation und Immunmodulation, je nach individueller Reaktionslage und Notwendigkeit:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/eigenblutbehandlung"
          }}>{`Eigenblutbehandlung`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/darmsanierung"
          }}>{`Darmsanierung`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/vitamin-c-infusion"
          }}>{`Vitamin-C-Infusion`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/therapien/homoeopathie"
          }}>{`Homöopathie`}</a></li>
      </ul>
      <p>{`Nur ein gesundes und starkes Immunsystem ist in der Lage, den täglichen Abwehrkampf optimal zu bestehen und den Körper vor Mutationen und bösartigen Zellveränderungen zu schützen (`}<a parentName="p" {...{
          "href": "/beschwerdebilder/vorsorge_praevention"
        }}>{`Vorsorge / Prävention`}</a>{`). Auch im Rahmen der `}<a parentName="p" {...{
          "href": "/beschwerdebilder/begleitende-krebstherapie"
        }}>{`Tumornachsorge`}</a>{` ist die Immunstärkung von grundlegender Bedeutung.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      